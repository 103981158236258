import React, { useEffect, useState } from "react";
import yellowArrow from "../../images/icons/yellow-arrow-right.png";
import lab from "../../images/stock/laboratory.jpg";
import axios from "axios";
import LoadingText from "../../utils/LoadingText";

const Info = () => {
  const [name, setName] = useState("");
  const [institution, setInstitution] = useState("");
  const [role, setRole] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [errors, setErrors] = useState([]);
  const [loading, setLoading] = useState(false);
  const [successfulSubmit, setSuccessfulSubmit] = useState(false);
  const [showErrors, setShowErrors] = useState(false);
  const [showForm, setShowForm] = useState(true);
  const [nameCount, setNameCount] = useState(50);
  const [instCount, setInstCount] = useState(80);
  const [roleCount, setRoleCount] = useState(50);
  const [emailCount, setEmailCount] = useState(150);
  const [submittedData, setSubmittedData] = useState({});

  useEffect(() => {
    let validationErrors = [];
    const emailFormat = new RegExp(
      /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
    );
    if (!name) validationErrors.push("Name required.");
    if (nameCount < 0) validationErrors.push("Name is too long.")
    if (!institution) validationErrors.push("Institution required.");
    if (instCount < 0) validationErrors.push("Institution is too long.")
    if (!role) validationErrors.push("Role required.");
    if (roleCount < 0) validationErrors.push("Role is too long.")
    if (!phone) validationErrors.push("Phone number required.");
    if (!email.match(emailFormat)) validationErrors.push("Invalid email.");
    if (!email) validationErrors.push("Email required.");
    if (emailCount < 0) validationErrors.push("Email is too long.")
    setErrors(validationErrors);
  }, [name, institution, role, phone, email]);

  const updateName = (letter) => {
    let filtered = letter.replace(/[^a-z\s]/gi, "");
    setName(filtered);
    setShowErrors(false);
    return filtered;
  };

  const updateInstitution = (letter) => {
    let filtered = letter.replace(/[^a-z\s-]/gi, "");
    setInstitution(filtered);
    setShowErrors(false);
    return filtered;
  };

  const updateRole = (letter) => {
    let filtered = letter.replace(/[^a-z\s-]/gi, "");
    setRole(filtered);
    setShowErrors(false);
    return filtered;
  };

  const updatePhone = (number) => {
    setPhone(number.replace(/[^0-9]/gi, ''));
    setShowErrors(false);
  };

  const updateEmail = (value) => {
    setEmail(value);
    return value;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (errors.length) {
      setShowErrors(true);
      return;
    } else {
      setLoading(true);
      setShowForm(false);

      let body = {
        Email: email,
        Name: name,
        Role: role,
        Phone: phone,
        Institution: institution,
        CreatedAt: new Date(),
      };

      let veryBasedUrl = '';
      if (process.env.NODE_ENV === 'development') veryBasedUrl = 'https://localhost:7193/'
      else veryBasedUrl = 'https://penpalserver.azurewebsites.net/';

      axios.post(
        `${veryBasedUrl}api/v1/InfoForms`,
        body
      ).then(function (response) {
        console.log('RESPONSE: ', response);
        if (response.status === 200) {
          setLoading(false);
          setShowForm(false);
          setSuccessfulSubmit(true);
        }
      })
        .catch(function (error) {
          console.log('ERROR: ', error);
          setErrors([error.message]);
          setShowForm(true);
          setShowErrors(true);
          setLoading(false);
        });
    }
  };

  const resetForm = () => {
    setName("");
    setInstitution("");
    setRole("");
    setPhone("");
    setEmail("");
    setNameCount(50);
    setInstCount(80);
    setRoleCount(50);
    setEmailCount(150);
    setSuccessfulSubmit(false);
    setLoading(false);
    setShowForm(true);
  };

  const updateCount = (field, value) => {
    switch (field) {
      case 'name': {
        let x = updateName(value)
        setNameCount(50 - x.length)
        return
      }
      case 'institution': {
        let x = updateInstitution(value)
        setInstCount(80 - x.length)
        return
      }
      case 'role': {
        let x = updateRole(value)
        setRoleCount(50 - x.length)
        return
      }
      case 'email': {
        let x = updateEmail(value)
        setEmailCount(150 - x.length)
        return
      }
      default:
        break;
    }
  }

  return (
    <div className="w-screen bg-gray-900 z-[0] min-h-[60vh]">
      <div className="fixed -top-[64px] w-screen h-screen brightness-50 overflow-hidden z-[0]">
        <img
          src={lab}
          alt="top-down view of neighborhood"
          className="object-cover object-center h-full w-full z-[-9]"
        />
      </div>
      <div
        style={{ fontFamily: "MAGISTRAL-BOOK" }}
        className="flex flex-col w-full items-center"
      >
        <p className="text-3xl lg:text-5xl m-2 pt-8 pb-4 text-sunburst text-shadow-sm font-magBold lg:m-8 z-10">
          WHAT IF YOU COULD
        </p>
        <ul className="lg:pl-44 flex flex-col text-yellow-700 font-magBook z-10">
          <li className="flex flex-row items-center">
            <img
              src={yellowArrow}
              alt="yellow arrow"
              className="w-6 relative bottom-1 mr-2"
            />
            <p className="text-sm text-shadow lg:text-2xl my-1 text-white">
              <b>
                <i>DETECT MOUSE PUPS</i>
              </b>
            </p>
          </li>
          <li className="flex flex-row items-center">
            <img
              src={yellowArrow}
              alt="yellow arrow"
              className="w-6 relative bottom-1 mr-2"
            />
            <p className="text-sm text-shadow lg:text-2xl my-1 text-white">
              <b>
                <i>MONITOR LIGHT, TEMPERATURE, HUMIDITY</i>
              </b>
            </p>
          </li>
          <li className="flex flex-row items-center">
            <img
              src={yellowArrow}
              alt="yellow arrow"
              className="w-6 relative bottom-1 mr-2"
            />
            <b>
              <p className="text-sm text-shadow lg:text-2xl my-1 text-white">
                <i>COLLECT CENSUS, and MORE</i>
              </p>
            </b>
          </li>
        </ul>
        <p className="text-lg my-6 font-magBold text-sunburst text-shadow-sm lg:text-4xl lg:m-8 z-10">
          All with one platform, 24/7/365.
        </p>
        <p className="bg-gray-900/70 w-11/12 md:w-auto rounded-lg py-3 px-4 leading-tight mx-8 mb-8 font-chaletSixtyMed lg:w-5/12 tracking-wider text-justify lg:text-xl lg:mb-8 text-white z-10 max-w-[600px]">
          Tricorder Array Technologies and UAB are working on that solution.
          We'd love to speak with you to ensure our product captures all of your
          needs.
        </p>
        {loading ? (
          <div className="lg:my-24 w-10/12 max-w-[615px] flex flex-col justify-center items-center bg-gray-900 py-6 mb-8 border border-sunburst rounded-3xl z-10">
            <LoadingText string={"Submitting..."} />
          </div>
        ) : showForm ? (
          <form
            className="flex flex-col w-11/12 sm:w-6/12 lg:w-4/12 xl:w-4/12 my-4 p-6 lg:my-8 bg-gray-900 rounded-3xl max-w-[440px] z-10"
            onSubmit={(e) => handleSubmit(e)}
          >
            {errors.length && showErrors ? (
              <div className="text-red-500 italic w-full flex flex-col justify-center items-center">
                <div className="flex flex-col">
                {errors.map((e) => {
                  return <p key={e}>{e}</p>;
                })}
                </div>
              </div>
            ) : null}
            <div className="flex flex-col mb-2 lg:mb-6">
              <div className="text-white flex items-center justify-between">
                <label className="">NAME</label>
                {nameCount < 50 ? <p className={`${nameCount < 0 ? "text-red-600" : ""}`}>{nameCount}</p> : null}
              </div>
              <input
                className="rounded border-sunburst focus:border-soft-blue pl-2 py-1"
                type="text"
                value={name}
                onChange={(e) => updateCount('name', e.target.value)}
              ></input>
            </div>
            <div className="flex flex-col mb-2 lg:mb-6">
              <div className="text-white flex items-center justify-between">
                <label className="text-white">INSTITUTION</label>
                {instCount < 80 ? <p className={`${instCount < 0 ? "text-red-600" : ""}`}>{instCount}</p> : null}
              </div>
              <input
                className="rounded border-sunburst focus:border-soft-blue pl-2 py-1"
                type="text"
                value={institution}
                onChange={(e) => updateCount('institution', e.target.value)}
              ></input>
            </div>
            <div className="flex flex-col mb-2 lg:mb-6">
              <div className="text-white flex items-center justify-between">
                <label className="text-white">ROLE</label>
                {roleCount < 50 ? <p className={`${roleCount < 0 ? "text-red-600" : ""}`}>{roleCount}</p> : null}
              </div>
              <input
                className="rounded border-sunburst focus:border-soft-blue pl-2 py-1"
                type="text"
                value={role}
                onChange={(e) => updateCount('role', e.target.value)}
              ></input>
            </div>
            <div className="flex flex-col mb-2 lg:mb-6">
              <label className="text-white">PHONE</label>
              <input
                className="rounded border-sunburst focus:border-soft-blue pl-2 py-1"
                type="tel"
                value={phone}
                onChange={(e) => updatePhone(e.target.value)}
              />
            </div>
            <div className="flex flex-col mb-1 lg:mb-6">
              <div className="text-white flex items-center justify-between">
                <label className="text-white">EMAIL</label>
                {emailCount < 150 ? <p className={`${emailCount < 0 ? "text-red-600" : ""}`}>{emailCount}</p> : null}
              </div>
              <input
                className="rounded border-sunburst focus:border-soft-blue pl-2 py-1"
                type="email"
                value={email}
                onChange={(e) => updateCount('email', e.target.value)}
              ></input>
            </div>
            <div className="w-full flex justify-end mt-4">
              <button
                type="submit"
                className="inline-flex items-center justify-center px-2 py-1 lg:px-4 lg:py-2 text-base font-medium leading-6 text-gray-600 whitespace-no-wrap bg-white border border-gray-200 rounded-md shadow-sm hover:bg-gray-100 enabled:bg-sunburst enabled:text-black enabled:border-sunburst enabled:hover:bg-yellow-300 focus:outline-none focus:shadow-none"
              >
                Submit
              </button>
            </div>
          </form>
        ) : successfulSubmit ? (
          <div className="lg:my-24 w-10/12 max-w-[615px] flex flex-col justify-center items-center bg-gray-900 py-6 mb-8 border border-sunburst rounded-3xl z-10">
            <p className="w-10/12 lg:mt-4 text-white lg:text-4xl text-lg leading-snug md:leading-normal">
              Thanks for letting us know you're interested in our work. Someone from Tricorder will reach out soon to see how we can best help you.
            </p>
          </div>
        ) : (
          <div className="lg:my-24 w-10/12 max-w-[615px] flex flex-col justify-center items-center bg-gray-900 py-6 mb-8 border border-sunburst rounded-3xl z-10">
            <h5 className="lg:mt-4 text-sunburst lg:text-4xl text-2xl ">
              Whoops! Something went wrong.
            </h5>
            <p className="lg:mt-4 text-white lg:text-xl text-center w-9/12">
              Please try re-sending the form or reach out to us at the number or email
              provided below!
            </p>
            <button
              type="button"
              onClick={resetForm}
              className="mt-6 inline-flex items-center justify-center px-4 py-2 text-base font-medium leading-6 text-gray-600 whitespace-no-wrap bg-white border border-gray-200 rounded-md shadow-sm hover:bg-gray-100 enabled:bg-sunburst enabled:text-black enabled:border-sunburst enabled:hover:bg-yellow-300 focus:outline-none focus:shadow-none"
            >
              Return to Form
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default Info;
